<template>

  <v-container>
    <h1 class="display-1 pb-3">Accessibility Statement for Impactstory, Inc. (DBA OurResearch) websites and products
    </h1>
    <p>
      In order to provide excellent service to all of our users, including those with disabilities, the OurResearch
      websites, products, and services aim to provide an accessible experience for users of all abilities. OurResearch
      has developed our websites and products with accessibility in mind. This Accessibility Statement applies to these
      tools and websites:
    </p>
    <ul style="margin: 10px 0px 30px 30px;">
      <li><a href="https://unsub.org">unsub.org</a></li>
      <li><a href="https://unpaywall.org">unpaywall.org</a></li>
      <li><a href="https://ourresearch.org">ourresearch.org</a></li>
      <li><a href="https://openalex.org">openalex.org</a></li>
      <li>Other websites and tools created and hosted by OurResearch</li>
    </ul>


    <h2>Conformance status</h2>

    <p>
      Our improvements are guided by relevant portions of the Web Content Accessibility Guidelines 2.0, Level AA and
      other recommendations. We have included accessibility features such as: navigability without use of a keyboard,
      text alternatives for non-text content, and assigned language for web pages. OurResearch websites are partially
      conformant with WCAG 2.1 level AA. Partially conformant means that some parts of the content do not fully conform
      to the accessibility standard.
    </p>
    <p>
      If you have questions or concerns about the accessibility of our websites, tools, or services, please contact us
      by sending an email to team@ourresearch.org. Please provide specific details about the accessibility issue,
      including the web address that may have caused challenges.
    </p>

    <h2>Reasonable accommodations</h2>
    <p>
      Individuals who need a reasonable accommodation to access OurResearch's products and services can make a request
      via email to team@ourresearch.org. Be sure to include contact information such as an email address or telephone
      number where you can be reached. Depending on the request, OurResearch may need sufficient notice to provide a
      reasonable accommodation.
    </p>

    <h2>Third-party websites</h2>
    <p>
      OurResearch's websites contains links to websites hosted by third parties. OurResearch does not make claims
      regarding the accessibility of third-party websites and is not able to make accommodations on such websites.
    </p>

    <h2>
      Feedback
    </h2>
    <p>
      We are always working to ensure that our websites, products, and services are accessible to users of all
      abilities. If you have a suggestion or question about accessibility of services at OurResearch, please contact us
      at team@ourresearch.org.
    </p>


  </v-container>


</template>

<script>
export default {
  name: "Accessibility",
  metaInfo() {
    return {
      title: "Accessibility"
    }
  },
}
</script>

<style scoped>

</style>